<template>
	<div v-loading="loading" :class="themeClass" class="max-width1100 margin-t-20" style="z-index: 10;">
		<div class="not-found-box border-F2F2F2 maink width-fill flex-row-align-center margin-b-10" v-if="noneFlag">
			<div class="not-found-innerbox flex-row-align-center font-size14 font-color-666">
				<span class="iconfont icon-tishi3 font-size14 font-color-FF0000"></span>
				<span class="margin-l-10">未在</span>
				<span class="font-color-FF0000">{{ keywords }}</span>
				<span class="">找到符合条件的相关商品</span>
			</div>
		</div>
		<div class="margin-b-10">
			<span>筛选条件：</span>
			<el-tag style="margin-right:10px;"
			 v-if="tags.length != 0" 
			>
			  <span style="color:#666666">商品类型：</span><span style="color: #0173FE;" v-for="(item,index) in tags"
			  :key="item.fGoodsClassID">{{item.fGoodsClass}}</span>
			</el-tag>
			<el-tag style="margin-right:10px;"
			  v-for="(item,index) in tagsData"
			  :key="item.index"
			  closable
			  @close="tagclose(item)"
			>
			 <span style="color:#666666">{{item.fClassPropertie}}：</span><span style="color: #0173FE;">{{item.fClassPropertieData}}</span>
			</el-tag>
			<el-tag style="margin-right:10px;" v-if="keywordData.length !=0"
			  v-for="(item,index) in keywordData"
			  v-show="item"
			  :key="item"
			  closable
			  @close="tagckeywork()"
			>
			 <span style="color:#666666">模糊搜索：</span><span style="color: #0173FE;">{{item}}</span>
			</el-tag>
		</div>
		<div class="margin-b-10 border-F2F2F2 radius5 font-size12" v-if="!noneFlag">
			<div style="display: flex" class="select-box">
				<div class="select-left bg-F2F4FD margin-r-20 flex-row-center-center">
					<div class="margin-tb-auto">商品类型</div>
				</div>
				<div class="select-right flex-row-wrap padding-t-10">
					<div class="right-box pointer-color-theme margin-r-20 margin-b-10" v-for="(item, index) of classifyList" :key="index"
					 :class="item.selected == true ? 'color-theme' : ''" @click="cate1SelectClick(item)">
						{{ item.fGoodsClass }}
					</div>
				</div>
			</div>
			<div v-for="(items, indexs) of cate2" :key="indexs" :class="cate2.length != indexs + 1 ? 'select-box' : ''" style="display: flex">
				<div class="select-left bg-F2F4FD margin-r-20 flex-row-center-center">
					<div class="margin-tb-auto">{{ items.fClassPropertie }}</div>
				</div>
				<div class="select-right flex-row-wrap padding-t-10">
					<!-- 'font-color-ccc': item2.optional == false -->
					<div class="right-box pointer-color-theme margin-r-20 margin-b-10" :class="{
							'color-theme': item2.selected == true,

						}"
					 v-for="(item2, indexw) of items.goodsClassPropertieDatas" :key="'data' + indexw" @click="cate3SelectClick(item2)">
						{{ item2.fClassPropertieData }}
					</div>
				</div>
			</div>
		</div>
		<div class="flex-row border-bottom-theme" v-if="allData.searchResult !== 0">
			<div class="tab-box text-center font-size13" :class="item.checked ? 'background-color-theme' : 'pointer-color-theme'"
			 v-for="(item, index) of tabData" :key="index" @click="tabClick(item.id)">
				{{ item.name }}
			</div>
		</div>
		<div class="padding-lr-15 padding-t-15 border-F2F2F2 flex-colum">
			<div v-if="goodsListShow">
				<div class="flex-row-space-between margin-b-15" v-if="!noneFlag">
					<div class="flex-row">
						<div class="goodsTab-box text-center font-size13 pointer" :class="item.checked ? 'color-theme' : 'border-r-F2F2F2'"
						 v-for="(item, index) of goodsTabData" :key="index" @click="goodsTabClick(item)">
							{{ item.name }}
							<span :class="item.fSortMode == 1 ? 'el-icon-top' : 'el-icon-bottom'"></span>
							<!-- <span :class="item.fSortMode==2 ? '' : 'el-icon-bottom'"></span> -->
						</div>
					</div>
					<div class="flex-row margin-tb-auto">
						<div>{{ total }}&nbsp;件商品</div>
						<div class="el-icon-arrow-left margin-tb-auto margin-rl-10 pointer-color-theme" @click="pageChange('-')"></div>
						<div>
							<span class="color-theme margin-r-5">{{ currentPage }}</span>
							<span>/</span>
							<span class="margin-l-5">{{ Math.ceil(total / pagesize) }}</span>
						</div>
						<div class="el-icon-arrow-right margin-tb-auto margin-rl-10 pointer-color-theme" @click="pageChange('+')"></div>
					</div>
				</div>
				<div v-if="goodsData.length == 0" class="flex-row-center-center">
					<svg-icon icon-class="NoContent" />
				</div>
				<commodity :Data="goodsData" v-loading="shoploadings" @toDetail="toDetail" @toshop="toshop" @countDownEnd="countDownEnd"></commodity>
				<div class="flex-row-center-flex-end margin-t-20 margin-b-30">
					<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :currentPage="currentPage"
					 :pagesize="pagesize" :total="total"></pagination>
				</div>
			</div>
			<div v-if="shopListShow">
				<div class="flex-row-space-between margin-b-15">
					<div></div>
					<div class="h37 flex-row margin-tb-auto">
						<div>{{ total }}家店铺</div>
						<div class="el-icon-arrow-left margin-tb-auto margin-rl-10 pointer-color-theme" @click="pageChange('-')"></div>
						<div>
							<span class="color-theme margin-r-5">{{ currentPage }}</span>
							<span>/</span>
							<span>{{ Math.ceil(total / pagesize) }}</span>
						</div>
						<div class="el-icon-arrow-right margin-tb-auto margin-rl-10 pointer-color-theme" @click="pageChange('+')"></div>
					</div>
				</div>
				<div v-if="goodsData.length == 0" class="flex-row-center-center">
					<svg-icon icon-class="NoShop" />
				</div>
				<div class="flex-row-align-center shop-box margin-b-13 border-F2F2F2 radius5 padding-15" v-for="(item, index) of shopListData"
				 :key="index" @click="enterTheStore(item)">
					<div class="shop-left">
						<div class="flex-column-center-center">
							<div class="img-box border-E4E4E4 flex-row-center-center radius5 margin-b-10 bg-fff">
								<img :src="item.shop.fShopLogoPath == '' ? imgurl : item.shop.fShopLogoPath" alt="" class="img-style" />
							</div>
							<div class="font-size13 twoline-show-hide margin-b-5" style="line-height: 20px" :title="item.shop.fShopName">{{ item.shop.fShopName }}</div>
						</div>
						<div class="flex-row font-size12">
							<div class="tag-box1 bg-FF0000 text-center radius2 font-color-fff margin-r-5">店铺</div>
							<div class="font-color-666">{{ item.shop.followDetil != null ? item.shop.followDetil.focus : 0 }}人关注</div>
						</div>
					</div>
					<div class="flex-row-space-between" style="width: 100%">
						<div class="flex-row">
							<div class="shop-right flex-row-space-between pointer" :class="item.recommendgoods.length == index1 ? '' : 'margin-r-30'"
							 v-for="(item1, index1) of item.recommendgoods" :key="index1">
								<div class="flex-colum">
									<div class="img-box1 border-E4E4E4 flex-row-center-center radius5 margin-b-10 bg-fff" style="position:relative;"><img :src="item1.fPicturePath"
										 alt="" class="img-style1" />
										 <!--是否售罄-->
										 <!--是否已售罄-->
										 <div v-if='item1.fStatus == 2 ? true:false' class='box-sellout'  >
										 	<img src="@/assets/sellout.png" alt="" style="width: 100%;height: 100%;">
										 </div>
										 </div>
									<div class="font-size12 hide-text shopname-box" :title="item1.fGoodsFullName">{{ item1.fGoodsFullName }}</div>
									<div class="font-color-FF0000">
										<span class="font-weight700"><span v-if='item1.fGoodsMinPrice == item1.fGoodsPrice ? false:true'>{{ item1.fGoodsMinPrice }}~</span><span>{{ item1.fGoodsPrice }}</span> </span>
										<span class="margin-r-5">元/{{ item1.fUnitName }}</span>
										<span class="font-color-999 line-through">{{ item1.yuanjia }}</span>
									</div>
								</div>
							</div>
						</div>
						<div class="shopBtn-box border-theme color-theme radius30 text-center font-size13">进店</div>
					</div>
				</div>
				<div class="flex-row-center-flex-end margin-t-20 margin-b-30">
					<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :currentPage="currentPage"
					 :pagesize="pagesize" :total="total"></pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters,
		mapState,
		watch
	} from 'vuex';
	import _ from 'lodash';
	import commodity from '@/components/goods/commodity.vue';
	import pagination from '@/components/pagination/pagination.vue';
	import {
		mapActions
	} from 'vuex';
	export default {
		components: {
			commodity,
			pagination
		},
		data() {
			return {
				imgurl: require('@/assets/shoplogo.png'),
				keywords: '', // 搜索关键字
				classifyList: [], // 所有分类
				cate2: [], // 二级和三级分类
				cate1Id: '', // 当前选中的一级分类
				queryList: [], // 选中三级分类id
				allData: {}, // 商品和店铺数据
				goodsData: [], // 商品数据
				shopListData: [], // 店铺数据
				shoploadings: false,
				goodsclassid: "",

				// 商品店铺切换tab
				tabData: [{
						id: 1,
						name: '商品',
						checked: true
					},
					{
						id: 2,
						name: '店铺',
						checked: false
					}
				],
				// 商品tab
				goodsTabData: [{
						id: 1,
						name: '综合',
						checked: true,
						fSortMode: 1
					},
					{
						id: 3,
						name: '价格排序',
						checked: false,
						fSortMode: 1
					},
					{
						id: 2,
						name: '销量排序',
						checked: false,
						fSortMode: 1
					}
				],
				goodsListShow: true, //是否显示商品列表
				shopListShow: false, //是否显示商品列表
				total: null, //总条数
				pagesize: 15, //每页显示条目个数
				currentPage: 1, //当前页
				optionalList: [], //可选的数据
				// 根据属性值获取可选属性值的请求参数
				requestParam: {
					filter: '',
					propertieDatas: []
				},
				// 根据分类获取商品列表数据的请求参数
				classRequestParam: {
					fAppType: 2,
          fAreaCode: '',
					classPropertieDataSearchDtos: [],
					pagedAndSortedDto: {
						maxResultCount: 15,
						filter: '',
						sorting: '',
						skipCount: 0
					},
					fSortType: 1, //1 综合 2销量 3价格
					fSortMode: 1 //1降序 2升序
				},
				loading: true,
				noneFlag: false,
				search: '',
				tags:[],
				tags3:[],
				tagsData:[],
				tagslist:[],
				keywordData:[],
				shouPath:"",
				detailobj:{},    //用于商品详情页面的传参
				province: {}, // 根据选择区域获取的省份信息
			};
		},
		computed: {
			...mapGetters(['getThemeName', 'getSearchValue','getclassifyListValue','gethomeIsGoodsValue','getGoodsDetailsIsGoodsValue', 'getareaCut']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
			// sear () {
			//   let a=_.cloneDeep(this.$store.getters.getSearch);　　//需要监听的数据
			//   return a
			// }
		},
		watch: {
			$route:{
				immediate:true,
				handler(to){
					if(to.path !="/Goods" && to.path !="/Goods/GoodsDetails"){
						this.changeGoodsDetailsIsGoodsData('')
						this.changeSearchData('')
					}
				}
			},
		  getSearchValue: {
			  handler(newValue, old) {
				this.keywords = newValue;
			  },
			  deep: true,
		  },
      getareaCut: {
        handler(newValue, old) {
          this.getGodPro()
        },
        deep: true,
      },
			optionalList: function(val) {
				if (val != null) {
					let arr2 = [];
					val.forEach(item => {
						item.goodsClassPropertieDatas.forEach(items => {
							arr2.push(items.fClassPropertieDataID);
						});
					});
					arr2 = Array.from(new Set(arr2)); //去重
					this.cate2.forEach(item1 => {
						item1.goodsClassPropertieDatas.forEach(item2 => {
							if (arr2.includes(item2.fClassPropertieDataID)) {
								item2.optional = true;
							} else {
								item2.optional = false;
							}
						});
					});
					this.cate2.forEach(item1 => {
						item1.goodsClassPropertieDatas.forEach(item2 => {
							// console.log(item2.selected, item2.optional);
						});
					});
					this.$forceUpdate();
				}
			},
			allData: function(val) {
				this.goodsData = this.allData.goods.items;
				this.shopListData = this.allData.shops.items;
				for (let item of this.shopListData) {
					if (item.recommendgoods.length > 5) {
						item.recommendgoods = item.recommendgoods.slice(0, 6);
					}
				}
				if (this.goodsListShow == true) {
					this.total = this.allData.goods.totalCount;
				}
				this.loading = false;
			},
			currentPage: function(val) {
				this.getListByGoodsClass(this.goodsclassid);
			},
			// 监听搜索条件，响应相应的商品
			keywords: function(val) {
				if (this.goodsclassid != '') {
					this.keywords = val
					if(this.keywords !=""){
						this.keywordData =[this.keywords]
						this.shouPath=this.$store.getters.getshouPathValue
						
					}else if(this.keywords ==""){
						this.keywordData =[]
					}
					if(this.shouPath == '/Goods'){
						console.log(this.keywords)
						this.getProByProLr();
					}
				}
			},
		},
	    activated() {
			this.currentPage = 1
			this.getGodPro(); //商品类型后面的数据
			this.keywords = this.$store.getters.getSearchValue
			if(this.keywords !=""){
				this.keywordData =[this.keywords]
			}else if(this.keywords ==""){
				this.keywordData =[]
			}
		},
		// 组件失活时
		deactivated() {
			// this.changeSearchData('')
			this.changeshouPathValue('')
			this.changehomeIsGoodsData('')
			this.keywords='';
			this.keywordData =[]
		},
		// 监听刷新按钮可以使用window对象的beforeunload事件
		mounted() {
		  window.addEventListener('beforeunload', this.handleRefresh);
		},
		// 在组件销毁的时候，需要移除事件监听器，以防止内存泄漏
		beforeDestroy() {
		  window.removeEventListener('beforeunload', this.handleRefresh);
		},
		methods: {
			handleRefresh() {
				sessionStorage.setItem('currentPage', this.currentPage)
			    // 执行刷新时的操作
				this.changeSearchData('')
				this.changeshouPathValue('')
				this.changeclassifyListData('')
				this.changehomeIsGoodsData('')
				this.changeGoodsDetailsIsGoodsData('')
				this.keywords='';
				this.keywordData =[]
			  },
			...mapActions(['changeSearchData','changeshouPathValue','changeclassifyListData','changehomeIsGoodsData','changeGoodsDetailsIsGoodsData']),
			tagckeywork(){ //点击模糊查询后面的那个x
				this.changeSearchData('')
				this.changeshouPathValue('')
				this.shouPath=""
				this.keywords='';
				this.keywordData =[]
				this.getProByProLr();
			},
			//进店
			enterTheStore(obj) {
				this.$router.push({
					name: 'storeList',
					query: {
						id: obj.shop.fShopUnitID
					}
				});
			},
			//选中条件删除事件
			deleteClick() {},
			//选择一级分类
			cate1SelectClick(value) {
					this.tagsData=[]
					this.tags=[value]
				// 排他,将当前选中项自定义属性设为true
				// 重新为二级分类赋值
				this.shoploadings = true
				this.classifyList.forEach(item => {
					item.selected = false;
					if (item.fGoodsClassID ==value.fGoodsClassID) {
						item.selected = true;
						this.cate2 = item.goodsClassProperties;
					}
				});
				this.goodsclassid = value.fGoodsClassID;
				this.queryList = [];
				this.tags3=[];
				this.classifyList.forEach(item => {
					item.goodsClassProperties.forEach(item2 => {
						item2.goodsClassPropertieDatas.forEach((item3, index) => {
							if (index == 0) {
								item3.selected = true
							} else {
								item3.selected = false
							}
						});
					});
				});
				this.currentPage = 1
				this.getProByProLr();

			},
			tagclose(val){
				this.tagsData.forEach((item,index)=>{
					if(val.fClassPropertieDataID == item.fClassPropertieDataID){
						 var arrIndex= this.tagsData.indexOf(item)
						 var arrRemoved =this.tagsData.splice(arrIndex,1)
						 this.cate2.forEach((cate2item,tste2Index)=>{
							 cate2item.goodsClassPropertieDatas.forEach((i,v)=>{
								 if(arrRemoved[0].fClassPropertieID == cate2item.fClassPropertieID){
								 	if (i.fClassPropertieDataID == '-1') {
								 		i.selected = true;
								 	} else {
								 		i.selected = false;
								 	} 							
								 }
							 })
						 })
					}

				})
				this.getProByProLr()
			},
			//选择三级分类
			cate3SelectClick(cate3) {
				this.classifyList.forEach(item => {
					if (item.fGoodsClassID == cate3.fGoodsClassID) {
						item.goodsClassProperties.forEach(item2 => {
							if (item2.fClassPropertieID == cate3.fClassPropertieID) {
								item2.goodsClassPropertieDatas.forEach(item3 => {
									if (item3.fClassPropertieDataID == cate3.fClassPropertieDataID) {
										if (item3.selected == false) {
											for (var i = 0; i < item2.goodsClassPropertieDatas.length; i++) {
												item2.goodsClassPropertieDatas[i].selected = false
											}
											item3.selected = true
										} else {
											// console.log('走的else11111111')
											// item3.selected = false
										}
									}
								});
							}
						});
					}
				});
				this.currentPage = 1
				this.getProByProLr();
			},
			//tab点击事件
			tabClick(id) {
				this.currentPage = 1;
				if (id == 1) {
					this.shopListShow = false;
					this.goodsListShow = true;
				} else {
					this.goodsListShow = false;
					this.shopListShow = true;
				}
				for (let item of this.tabData) {
					if (item.id == id) {
						item.checked = true;
					} else {
						item.checked = false;
					}
				}
				if (this.goodsListShow == true) {
					this.total = this.allData.goods.totalCount;
				} else {
					this.total = this.allData.shops.totalCount;
				}
			},
			//商品tab点击事件
			goodsTabClick(val) {
				const m = val;
				for (let item of this.goodsTabData) {
					if (item.id == m.id) {
						item.checked = true;
					} else {
						item.checked = false;
					}
				}
				// 判断点的是不是之前选中的
				if (this.classRequestParam.fSortType == m.id) {
					for (let item of this.goodsTabData) {
						if (item.id == m.id) {
							item.fSortMode = m.fSortMode == 1 ? 2 : 1;
							this.classRequestParam.fSortMode = item.fSortMode;
						} else {
							item.fSortMode = 1;
						}
					}
				} else {
					this.classRequestParam.fSortMode = 1;
					for (let item of this.goodsTabData) {
						item.fSortMode = 1;
					}
				}
				this.$forceUpdate();
				this.classRequestParam.fSortType = val.id;
				this.getListByGoodsClassLr(this.goodsclassid);
			},
			//换页、
			pageChange(type) {
				if (type == '+') {
					if (this.currentPage < Math.ceil(this.total / this.pagesize)) {
						this.currentPage++;
					}
				} else {
					if (this.currentPage > 1) {
						this.currentPage--;
					}
				}
			},
			//跳转商品详情
			toDetail(obj) {
				this.$router.push({
					name: 'GoodsDetails',
					query: {
						fGoodsID: JSON.parse(obj).fGoodsID,
						detailobj:this.detailobj
					}
				});
			},
			//跳转商品所在店铺
			toshop(obj) {
                this.$router.push({
					name: 'storeList',
					query: {
						id:JSON.parse(obj).fShopUnitID
					}
				})
			},
			//倒计时结束事件
			countDownEnd(val, index) {
				this.goodsData[index].countDownShow = val;
			},
			//pageSize（每页个数） 改变时会触发
			handleSizeChange(val) {},
			//currentPage（当前页数） 改变时会触发
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getListByGoodsClass(this.goodsclassid);
			},
			//获取商品属性值
			async	getGodPro() {

				console.log(this.$store.getters.getclassifyListValue)
				if(this.$store.getters.getclassifyListValue){
					this.classifyList =this.$store.getters.getclassifyListValue
				}else{
					let fGovCode = ''
					if (sessionStorage.getItem('district')) {
						let district =  JSON.parse(sessionStorage.getItem('district'))
						fGovCode = district.code
					} else {
						let city = JSON.parse(sessionStorage.getItem('city'))
						fGovCode = city.code
					}
					let res = await this.ApiRequestPost('/api/mall/goods/goods-class/get-area-navigations', {
						fGovCode: fGovCode
					});
					console.log(191919)
					this.classifyList = res.obj.goodsClasses;
				}
				
				this.tags= [this.classifyList[0]]
				if (this.$route.query.class) {
					this.goodsclassid = this.$route.query.class
				} else {
					this.goodsclassid = this.classifyList[0].fGoodsClassID
				}
				if(this.$store.getters.getGoodsDetailsIsGoodsValue){
					let classifyListS= this.$store.getters.getGoodsDetailsIsGoodsValue
					this.goodsclassid = classifyListS.fGoodsClassID
					this.getProByProLr();
				} else if (this.$store.getters.gethomeIsGoodsValue) { // // 2.首页分类跳转
					let cate3 = this.$store.getters.gethomeIsGoodsValue;
					this.goodsclassid = cate3.fGoodsClassID
					this.classifyList.forEach(item => {
						item.goodsClassProperties.forEach(item2 => {
							item2.goodsClassPropertieDatas.forEach((item3, index) => {
								if (index == 0) {
									item3.selected = true
								} else {
									item3.selected = false
								}
							});
						});
					});
					this.classifyList.forEach(item => {
						if (item.fGoodsClassID == cate3.fGoodsClassID) {
							item.selected = true;
							item.goodsClassProperties.forEach(item1 => {
								item1.goodsClassPropertieDatas.forEach((item2, index) => {
									if (item2.fClassPropertieID == cate3.fClassPropertieID && item2.fClassPropertieDataID == cate3.fClassPropertieDataID) {
										console.log('选中的数据', item2)
										item2.selected = true;
										if (item2.fClassPropertieDataID != -1) {
											item1.goodsClassPropertieDatas[0].selected = false
										}
										// item1.goodsClassPropertieDatas[0].selected = false
									} else {
										// console.log('赋值true')
										// item2.selected = false;
									}
									item2.optional = false;
								});
							});
							this.cate2 = item.goodsClassProperties;
							this.getProByProLr();
						} else {

							if (cate3.fGoodsClassID) {
								item.goodsClassProperties.forEach(item1 => {
									item1.goodsClassPropertieDatas.forEach((item2, index) => {
										// 每一项加选中的属性,默认全部是选中的
										if (index == 0) {
											item2.selected = true;
										} else {
											item2.selected = false;
										}
										item2.optional = true;
									});
								});
							} else {
								item.goodsClassProperties.forEach(item1 => {
									item1.goodsClassPropertieDatas.forEach((item2, index) => {
										// 每一项加选中的属性,默认全部是选中的
										if (index == 0) {
											item2.selected = true;
										} else {
											item2.selected = false;
										}
										item2.optional = true;
									});
								});


								this.classifyList[0].selected = true
								this.cate2 = this.classifyList[0].goodsClassProperties;
								this.getProByProLr();
							}

						}
					});


				} else if (this.$store.getters.getSearchValue || this.keywords) {
					console.log('走的search')
					// 首页关键字跳转
					// console.log("keywords");
					if (this.$store.getters.getSearchValue) { 
						this.keywords = this.$store.getters.getSearchValue
					} else {
						this.keywords = this.keywords
					}
					this.classifyList.forEach(item => {
						item.goodsClassProperties.forEach(item1 => {
							item1.goodsClassPropertieDatas.forEach(item2 => {
								if (item2.fClassPropertieDataID == '-1') {
									item2.selected = true;
								} else {
									item2.selected = false;
								}
							});
						});
					});
					if (this.cate1Id != '') {
						this.classifyList.forEach(item => {
							if (item.fGoodsClassID == this.cate1Id) {
								item.selected = true;
								this.cate2 = item.goodsClassProperties;
							} else {
								item.selected = false;
							}
						});
					} else {
						this.cate2 = this.classifyList[0].goodsClassProperties;
					}
					this.getProByPro();
				} else {
					if (sessionStorage.getItem('ClassPropertie')) {
						this.province =  JSON.parse(sessionStorage.getItem('ClassPropertie'))
					} else {
						this.province = {}
					}
					let cate3 = this.province;
					this.goodsclassid = 1
					this.classifyList.forEach(item => {
						item.goodsClassProperties.forEach(item2 => {
							item2.goodsClassPropertieDatas.forEach((item3, index) => {
								if (index == 0) {
									item3.selected = true
								} else {
									item3.selected = false
								}
							});
						});
					});
					this.classifyList.forEach(item => {
						if (item.fGoodsClassID == 1) {
							item.selected = true;
							item.goodsClassProperties.forEach(item1 => {
								item1.goodsClassPropertieDatas.forEach((item2, index) => {
									if (item2.fClassPropertieID == cate3.fClassPropertieID && item2.fClassPropertieDataID == cate3.fClassPropertieDataID) {
										item2.selected = true;
										if (item2.fClassPropertieDataID != -1) {
											item1.goodsClassPropertieDatas[0].selected = false
										}
										// item1.goodsClassPropertieDatas[0].selected = false
									} else {
										// item2.selected = false;
									}
									item2.optional = false;
								});
							});
							this.cate2 = item.goodsClassProperties;
							this.getProByProLr();
						} else {
							if (1) {
								item.goodsClassProperties.forEach(item1 => {
									item1.goodsClassPropertieDatas.forEach((item2, index) => {
										// 每一项加选中的属性,默认全部是选中的
										if (index == 0) {
											item2.selected = true;
										} else {
											item2.selected = false;
										}
										item2.optional = true;
									});
								});
							} else {
								item.goodsClassProperties.forEach(item1 => {
									item1.goodsClassPropertieDatas.forEach((item2, index) => {
										// 每一项加选中的属性,默认全部是选中的
										if (index == 0) {
											item2.selected = true;
										} else {
											item2.selected = false;
										}
										item2.optional = true;
									});
								});
					
					
								this.classifyList[0].selected = true
								this.cate2 = this.classifyList[0].goodsClassProperties;
								this.getProByProLr();
							}
					
						}
					});
					return
					// 直接点击tab到商品
					//1.默认情况
					// 对数据进行处理给一级分类和三级分类加自定义属性
					// let isclasscheck = false
					// this.classifyList.forEach(item => {
					// 	if (item.fGoodsClassID == this.goodsclassid) {
					// 		isclasscheck = true
					// 	}
					// 	item.selected = false;
					// 	item.goodsClassProperties.forEach(item1 => {
					// 		item1.goodsClassPropertieDatas.forEach(item2 => {
					// 			// 每一项加选中的属性,默认全部是选中的
					// 			if (item2.fClassPropertieDataID == '-1') {
					// 				item2.selected = true;
					// 			} else {
					// 				item2.selected = false;
					// 			}
					// 			item2.optional = true;
					// 		});
					// 	});
					// });

					// if (isclasscheck) {
					// 	this.classifyList.forEach(item => {
					// 		if (item.fGoodsClassID == this.goodsclassid) {
					// 			item.selected = true
					// 			this.cate2 = item.goodsClassProperties;
					// 		}
					// 	})
					// } else {
					// 	// 默认一级分类第一项被选中
					// 	this.classifyList[0].selected = true;
					// 	this.cate2 = this.classifyList[0].goodsClassProperties;
					// }

					// 默认显示第一个一级分类下的子分类
					// this.getProByProLr();
				}
			},
			// 根据商品属性值获取商品属性值
			async getProByPro() {
				this.shoploadings = true;
				this.requestParam.propertieDatas = [];
				this.tagsData=[]
				this.cate2.forEach(item1 => {
					item1.goodsClassPropertieDatas.forEach(item2 => {
						if (item2.selected == true) {
							this.requestParam.propertieDatas.push({
								fGoodsClassID: item2.fGoodsClassID,
								fClassPropertieID: item2.fClassPropertieID,
								fClassPropertieDataID: item2.fClassPropertieDataID,
								fClassPropertie:item1.fClassPropertie,
                                fClassPropertieData:item2.fClassPropertieData
							});
						}
					});
				});
				this.requestParam.filter = this.keywords;
				this.queryList = this.requestParam.propertieDatas;
				this.tags3 =this.requestParam.propertieDatas;
				this.tagsData =this.tags3.filter(function(val, valindex) {
						 return val.fClassPropertieDataID != '-1' ;
				})
				this.getListByGoodsClass(this.goodsclassid);
			},
			async getProByProLr() {
				this.shoploadings = true;
				this.requestParam.propertieDatas = [];
				this.tagsData=[]
				this.cate2.forEach(item1 => {
					item1.goodsClassPropertieDatas.forEach(item2 => {
						if (item2.selected == true) {
							this.requestParam.propertieDatas.push({
								fGoodsClassID: item2.fGoodsClassID,
								fClassPropertieID: item2.fClassPropertieID,
								fClassPropertieDataID: item2.fClassPropertieDataID,
								fClassPropertie:item1.fClassPropertie,
			                    fClassPropertieData:item2.fClassPropertieData
							});
						}
					});
				});
				this.requestParam.filter = this.keywords;
				this.queryList = this.requestParam.propertieDatas;
				this.tags3 =this.requestParam.propertieDatas;
				this.tagsData =this.tags3.filter(function(val, valindex) {
						 return val.fClassPropertieDataID != '-1' ;
				})
				this.getListByGoodsClassLr(this.goodsclassid);
			},
			// 根据分类获取商品列表数据
			async getListByGoodsClass(id) {
				content.scrollIntoView({behavior: 'auto',block: 'center',inline: 'nearest'})
				this.loading = true;
				console.log('最终发送的id', id)
				// console.log("根据分类获取商品列表数据");
				let list = [];
				this.queryList.forEach(item => {
					if (item.fClassPropertieDataID != '-1') {
						list.push(item);
					}
				});
				if(this.keywords ==''){
					this.classRequestParam.fGoodsClassID = id;
				}else{
					delete this.classRequestParam.fGoodsClassID
				}
				
				this.classRequestParam.classPropertieDataSearchDtos = list;
				this.classRequestParam.pagedAndSortedDto.filter = this.keywords;
				this.classRequestParam.pagedAndSortedDto.skipCount = this.pagesize * (this.currentPage - 1);
				// if (sessionStorage.getItem('district')) {
				// 	let district =  JSON.parse(sessionStorage.getItem('district'))
				// 	this.classRequestParam.fAreaCode = district.code
				// } else {
				// 	let city = JSON.parse(sessionStorage.getItem('city'))
				// 	this.classRequestParam.fAreaCode = city.code
				// }
				const res = await this.ApiRequestPost('api/mall/ebsale/goods-price/get-goods-by-goodsclass', this.classRequestParam);
				if(res.obj.goods.items.length !=0){
				   res.obj.goodsClasses.forEach((listItem,listIndex)=>{
					if(listItem.fGoodsClassID == res.obj.goods.items[0].fGoodsClassID){
						 listItem.selected =true
						
					}else {
						listItem.selected =false
					}
					if( listItem.selected ==true){
						this.$forceUpdate();
						this.getListByGoodsClassLr(listItem.fGoodsClassID)
					}
					})
					}else if(res.obj.goods.items.length ==0){
						  this.allData = res.obj;
						this.classifyList =res.obj.goodsClasses;
						this.classifyList.forEach((listItem,listIndex)=>{
						if(listItem.fGoodsClassID == this.tags[0].fGoodsClassID){
							listItem.selected =true;
						}
						
						this.tags3.forEach((x,indexNum)=>{
							if(listItem.fGoodsClassID == x.fGoodsClassID){
								listItem.goodsClassProperties.forEach(item1=>{
									item1.goodsClassPropertieDatas.forEach(itemdata=>{
										if (itemdata.fClassPropertieDataID !='-1') {
											itemdata.selected = false
										} else {
											itemdata.selected = true
										}
									})
								}) 
								this.$forceUpdate();
								 this.cate2=listItem.goodsClassProperties
								 this.cate2.forEach((cateItem,cateIndex)=>{
								    cateItem.goodsClassPropertieDatas.forEach((itemData,indexData)=>{
								 	this.tags3.forEach((itemName,indexName)=>{
								 	if(cateItem.fClassPropertieID == itemName.fClassPropertieID ){
								 	   if(itemName.fClassPropertieDataID ==itemData.fClassPropertieDataID){
								 				itemData.selected =true
								 			}else{
								 				itemData.selected =false
								 			}
								 	}
								  })  	
							    })	
								})
							}
						})
						})
						this.shoploadings = false;
					}
				
				
			},
			async getListByGoodsClassLr(id) {
					this.loading = true;
					let list = [];
					this.queryList.forEach(item => {
						if (item.fClassPropertieDataID != '-1') {
							list.push(item);
						}
					});
					this.classRequestParam.fGoodsClassID = id;
					this.classRequestParam.classPropertieDataSearchDtos = list;
					this.classRequestParam.pagedAndSortedDto.filter = this.keywords;
					this.classRequestParam.pagedAndSortedDto.skipCount = this.pagesize * (this.currentPage - 1);
					// if (sessionStorage.getItem('district')) {
					// 	let district =  JSON.parse(sessionStorage.getItem('district'))
					// 	this.classRequestParam.fAreaCode = district.code
					// } else {
					// 	let city = JSON.parse(sessionStorage.getItem('city'))
					// 	this.classRequestParam.fAreaCode = city.code
					// }
			
					this.detailobj={
						fAppType: this.classRequestParam.fAppType,
						// fAreaCode: this.classRequestParam.fAreaCode,
						fSortMode: this.classRequestParam.fSortMode,
						fSortType: this.classRequestParam.fSortType,
						fGoodsClassID:id,
						classPropertieDataSearchDtos:list,
						pagedAndSortedDto:this.classRequestParam.pagedAndSortedDto
						
					}
					const res = await this.ApiRequestPost('api/mall/ebsale/goods-price/get-goods-by-goodsclass', this.classRequestParam);
					this.allData = res.obj;
					this.classifyList =res.obj.goodsClasses;
					if(res.obj.goods.items.length !=0){
						this.classifyList.forEach((listItem,listIndex)=>{
							if(listItem.fGoodsClassID == res.obj.goods.items[0].fGoodsClassID ){
								 listItem.selected =true;
								 this.tags=[]	
								 this.tags=[listItem]
								 this.tags3.forEach((x,indexNum)=>{
								 		 this.cate2=listItem.goodsClassProperties
								 		 this.cate2.forEach((cateItem,cateIndex)=>{
								 		    cateItem.goodsClassPropertieDatas.forEach((itemData,indexData)=>{
												if (itemData.fClassPropertieDataID !='-1') {
													itemData.selected = false
												} else {
													itemData.selected = true
												}			
								 		 	this.tags3.forEach((itemName,indexName)=>{
								 		 	if(cateItem.fClassPropertieID == itemName.fClassPropertieID ){
								 		 	   if(itemName.fClassPropertieDataID ==itemData.fClassPropertieDataID){
								 		 				itemData.selected =true
								 		 			}else{
								 		 				itemData.selected =false
								 		 			}
								 		 	}
								 		  })  	
								 	    })	
								 		})
								 })
							}
							
						})
							this.shoploadings = false;
					}else if(res.obj.goods.items.length ==0){
						this.classifyList.forEach((listItem,listIndex)=>{
						if(listItem.fGoodsClassID == this.tags[0].fGoodsClassID){
							listItem.selected =true;
						}
						})
					}
					this.shoploadings = false;
					delete this.classRequestParam.fGoodsClassID
					if(this.$store.getters.getSearchValue ==""){
						this.changeGoodsDetailsIsGoodsData('')
						this.changeSearchData('')
					}
					
				}
		}
	};
</script>

<style lang="scss" scoped>
	.h37 {
		height: 37px;
		line-height: 37px;
	}

	.not-found-box {
		height: 58px;
		margin: 2px auto 10px;
		background-color: #ffffff;
		border-radius: 5px;
	}

	.not-found-innerbox {
		width: 100%;
		margin: 0 15px;
		padding: 0 15px;
		background-color: #fff2f2;
		height: 30px;
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.border-bottom-theme {
		@include themify($themes) {
			border-bottom: 1px solid themed('themes_color');
		}
	}

	.active-box {
		height: 85px;
		line-height: 46px;
	}

	.active-title {
		padding: 0 48px 0 29px;
	}

	.tag-box {
		height: 26px;
		line-height: 26px;
	}

	.select-box {
		border-bottom: 1px dashed #f2f2f2;
	}

	.select-left {
		min-height: 46px;
		height: auto;
		width: 118px;
	}

	.select-right {
		width: 981px;
	}

	.right-box {
		height: 26px;
		line-height: 26px;
	}

	.tab-box {
		height: 35px;
		line-height: 35px;
		width: 70px;
	}

	.goodsTab-box {
		width: 95px;
		height: 35px;
		line-height: 35px;
		border-left: 1px solid #f2f2f2;
		border-top: 1px solid #f2f2f2;
		border-bottom: 1px solid #f2f2f2;
	}

	.shop-box {
		height: 145px;
	}

	.shop-box:hover {
		background-color: #f2f4fd;
	}

	.shop-left {
		width: 117px;
		padding-right: 38px;
		margin-right: 38px;
		border-right: 1px solid #f2f2f2;
	}

	.img-box {
		width: 70px;
		height: 70px;
	}

	.img-style {
		width: 52px;
		height: 52px;
	}

	.tag-box1 {
		padding: 0 3px;
	}

	.shop-right {
		width: 97px;
	}

	.img-box1 {
		width: 97px;
		height: 97px;
	}

	.img-style1 {
		width: 67px;
		height: 89px;
	}

	.shopname-box {
		width: 97px;
	}

	.shopBtn-box {
		width: 75px;
		height: 24px;
		line-height: 24px;
	}

	.tip-box {
		height: 30px;
		line-height: 30px;
	}
	.box-sellout{
		min-width: 30px;
		width: 30px;
		height: 30px;
		position: absolute;
		right:10px;
		top: 10px;
		z-index: 100;
		padding: 0 10px;
		line-height: 24px;
	}
	/deep/.el-tag{
		background-color: #F8F9FE;
		border-color: #0173FE;
	}
</style>
