<!-- 
引入组件：import commodity from '@/components/goods/commodity.vue' 
注册组件：
components: {
	commodity
},

组件使用：<commodity :Data="goodsData" @toDetail="toDetail"></commodity>
goodsData：商品数据
@toDetail：跳转详情事件
 -->
<template>
	<div class="flex-row-wrap">
		<div class="flex-colum border-F2F4FD radius10 margin-b-10 goods-hover goods-box" :class="(index+1)%5 != 0?'margin-r-8':''"
		 v-for="(item,index) of Data" :key="index">
			<div class="img-box bg-F9FBFF flex-row-center-center border-top-radius-10 position-relative" @click="toDetail(item)">
				<div class="position-relative">
					<img :src="item.fPicturePath" alt="" class="img-style">
					<!-- 0右上角 -->
					<div class="box-one-row-buoy" v-if="item.couponInfo != null&& item.couponInfo.fDispArea == 0 && item.couponInfo.fIconPath != '' && item.couponInfo.fIconPath != undefined && item.couponInfo.fIconPath != null">
						<img :src="item.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;"> 
					</div>
					<!-- 1右下角 -->
					<div class="box-one-row-buoy1" v-if="item.couponInfo != null&& item.couponInfo.fDispArea == 1 && item.couponInfo.fIconPath != '' && item.couponInfo.fIconPath != undefined && item.couponInfo.fIconPath != null">
						<img :src="item.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;"> 
					</div>
					<!-- 2左下角 -->
					<div class="box-one-row-buoy2" v-if="item.couponInfo != null&& item.couponInfo.fDispArea == 2 && item.couponInfo.fIconPath != '' && item.couponInfo.fIconPath != undefined && item.couponInfo.fIconPath != null">
						<img :src="item.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;"> 
					</div>
					<!-- 3左上角 -->
					<div class="box-one-row-buoy3" v-if="item.couponInfo != null&& item.couponInfo.fDispArea == 3 && item.couponInfo.fIconPath != '' && item.couponInfo.fIconPath != undefined && item.couponInfo.fIconPath != null">
						<img :src="item.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;"> 
					</div>
					<!--是否已售罄-->
					<div v-if='item.fStatus == 2 ? true:false' class='box-sellout'  >
						<img src="@/assets/sellout.png" alt="" style="width: 100%;height: 100%;">
					</div>
					
					
				</div> 
				<div class="position-absolute activity-box font-color-fff padding-l-10 flex-row" v-if="item.countDownShow">
					<div class="margin-r-15">
						<div class="padding-t-5 font-size12">到手价</div>
						<div class="">￥<span class="font-size20">532</span></div>
					</div>
					<div class="margin-t-10 font-size12">
						<div class="padding-t-5">年终特惠全场8折起</div>
						<div class="flex-row">距结束剩：<countDowm :index="index" :remainTime="item.time" @countDownEnd="countDownEnd"></countDowm>
						</div>
					</div>
				</div>
				<div class="position-absolute positionTag-absolute" v-if="item.countDownShow"></div>
			</div>
			<div class="padding-lr-10 padding-tb-10">
				<div class="font-color-FF0000 font-size13" @click="toDetail(item)">
					¥<span class="font-size20"><span v-if='item.fGoodsMinPrice == item.fGoodsPrice ?false:true'>{{item.fGoodsMinPrice|NumFormat}}~</span><span>{{item.fGoodsPrice|NumFormat}}</span></span>元/{{item.fUnitName}}
				</div>
				<div class="title-box hide-text margin-b-10" :title="item.fGoodsFullName" @click="toDetail(item)">
					{{item.fGoodsFullName}}
				</div>
				<div class="flex-row font-size12 margin-b-5" @click="toDetail(item)" style="height: 16px;" v-if="item.fShopTypeID == '1'">
					<div class="tag-box1 text-center radius2 font-color-fff margin-r-5" style="line-height: 16px;">{{item.fShopTypeName}}</div>
					<div class="margin-tb-auto padding-lr-5 radius2 border-FF0000 font-color-FF0000" v-if="item.fActivityIntegralMultiple > 1" style="line-height: 15px;">
						{{item.fActivityIntegralMultiple}}倍积分
					</div>
				</div>
				<div class="font-size13 font-color-92ADF5 hide-text" @click="toshop(item)">
					<i class="el-icon-s-shop font-size18"></i>
					{{item.fShopName}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import countDowm from '@/components/countDown/countDown.vue'
	export default {
		components: {
			countDowm
		},
		props: {
			Data: {
				type: Array,
				default: []
			}
		},
		data() {
			return {
				countDownShow: true //倒计时开始
			}
		},
		methods: {
			//倒计时结束事件
			countDownEnd(val, index) {
				this.$emit('countDownEnd', val, index)
			},
			//跳转商品详情事件传出
			toDetail(obj) {
				this.$emit('toDetail', JSON.stringify(obj))
			},
			//跳转商品所在店铺事件传出
			toshop(obj) {
				this.$emit('toshop', JSON.stringify(obj))
			}
		}
	}
</script>

<style lang="scss" scoped>
	.goods-hover:hover {
		cursor: pointer;
		box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.15);
	}

	.goods-box {
		// height: 325px;
		width: 205px;
	}

	.img-box {
		width: 205px;
		height: 205px;
	}

	.img-style {
		width: 192px;
		height: 192px;
	}

	.title-box {
		width: 184px;
	}

	.tag-box1 {
		// width: 34px;
		// height: 17px;
		// line-height: 17px;
		padding: 0 3px;
		background-image: linear-gradient(to right top, #FF0000, #FF9999);
	}

	.activity-box {
		background: url(../../assets/imgs/goods/goods-activity.png) 100%;
		height: 53px;
		width: 195px;
		bottom: 0;
		z-index: 1;
	}

	.positionTag-absolute {
		background: url(../../assets/imgs/goods/goods-activity-tag.png) 100%;
		height: 55px;
		width: 65px;
		top: 0;
		left: 15px;
		z-index: 1;
	}

	.margin-r-8 {
		margin-right: 8px;
	}
	.box-one-row-buoy {
		min-width: 60px;
		width: 60px;
		height: 60px;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 10;
	}
	.box-one-row-buoy1{
		min-width: 60px;
		width: 60px;
		height: 60px;
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 10;
	}
	.box-one-row-buoy2{
		min-width: 60px;
		width: 60px;
		height: 60px;
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 10;
	}
	.box-one-row-buoy3{
		min-width: 60px;
		width: 60px;
		height: 60px;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
	}
	.box-sellout{
		min-width: 70px;
		width: 70px;
		height: 70px;
		position: absolute;
		right:10px;
		top: 10px;
		z-index: 100;
		padding: 0 10px;
		line-height: 24px;
	}
</style>
